import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../02_styles/Mcard.module.css';
import { selectMcard } from '../04_store/00_actions/pomeziaActions';

const Mcard = props => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(props.selected);
    const lockedMcards = useSelector(state => state.pomezia.lockedMcards);
    const isLocked = lockedMcards.map(mcard => mcard.id).includes(props.id);

    const dragstartHandler = e => {
        e.dataTransfer.setData('src', 'playerMcard');
    }

    const clickHandler = () => {
        dispatch(selectMcard(props.id));
        setSelected(!selected);
    }

    const cardStyle = selected ? `${styles.Mcard} ${styles.Selected}` : styles.Mcard;

    return (
        <div
            className={cardStyle}
            draggable
            onDragStart={dragstartHandler}
            onClick={clickHandler}>
            {isLocked && <div className={styles.Locked}>X</div>}
            <div className={styles.McardDescription}>{props.description}</div>
            
        </div>
    )
};

export default Mcard;