import { useState } from 'react';

import brick from '../05_assets/images/cards/brickSquare.png';
import wheat from '../05_assets/images/cards/wheatSquare.png';
import sheep from '../05_assets/images/cards/sheepSquare.png';
import stone from '../05_assets/images/cards/stoneSquare.png';
import wood from '../05_assets/images/cards/woodSquare.png';
import mcard from '../05_assets/images/cards/mcardSquare.png';
import { Colors } from '../03_constants/Colors';

import { useDispatch } from 'react-redux';
import styles from '../02_styles/CardSquare.module.css';
import { selectCard } from '../04_store/00_actions/pomeziaActions';

const pics = {brick, wheat, sheep, stone, wood, mcard}

const CardSquare = props => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(props.selected);

    const dragstartHandler = e => {
        e.dataTransfer.setData('src', 'playerCard');
    }

    const clickHandler = () => {
        dispatch(selectCard(props.id, props.type));
        setSelected(!selected);
    }

    return (
        <img
            style={{backgroundColor: !selected ? Colors[props.type] : '#9e9e9e80'}}
            src={pics[props.type]}
            className={styles.CardSquare}
            draggable
            onDragStart={dragstartHandler}
            onClick={clickHandler} />
    )
};

export default CardSquare;