import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';

import saxbarReducer from './04_store/01_reducers/saxbarReducer';
import pomeziaReducer from './04_store/01_reducers/pomeziaReducer';

const reducer = combineReducers({
  saxbar: saxbarReducer,
  pomezia: pomeziaReducer
})

const store = createStore(reducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
