import fiumani from '../05_assets/images/faces/fSquare.png';
import gambone from '../05_assets/images/faces/zSquare.png';
import grassi from '../05_assets/images/faces/gSquare.png';
import santancini from '../05_assets/images/faces/sSquare.png';
import cardBack from '../05_assets/images/cards/backSquare.png';
import mCardBack from '../05_assets/images/cards/mBackSquare.png';
import styles from '../02_styles/OtherPlayer.module.css';

const pics = {
    fiumani,
    gambone,
    grassi,
    santancini
}

const ManyCards = props => {
    return (
        <div className={styles.ManyCards}>
            <div className={props.mCardBackground ? `${styles.CardBackOne} ${styles.mCardBackground}` : styles.CardBackOne} />
            <div className={props.mCardBackground ? `${styles.CardBackTwo} ${styles.mCardBackground}` : styles.CardBackTwo} />
            <div className={styles.CardBackThree}>
                <img className={styles.CardBackImage} alt='card' src={props.image} />
            </div>
        </div>
    )
}

const Cards = props => {
    return (
        <div className={styles.CardBack}>
            <img className={styles.CardBackImage} alt='card' src={props.image} />
        </div>
    )
}

const OtherPlayer = props => {
    const style = !props.last ? styles.OtherPlayer : styles.LastOtherPlayer;
    let totalCards = '';
    if (props.totalCards === 0) totalCards = null;
    else if (props.totalCards === 1) totalCards = <Cards image={cardBack} />;
    else totalCards = <ManyCards image={cardBack}/>;

    let totalMcards = '';
    if (props.totalMcards === 0) totalMcards = null;
    else if (props.totalMcards === 1) totalMcards = <Cards image={mCardBack} />;
    else totalMcards = <ManyCards image={mCardBack} mCardBackground/>;

    return (
        <div
            className={style}
            onDragOver={e => props.dragOver(e)}
            onDrop={e => props.drop(e, props.name)}
            onClick={e => props.click(e, props.name)}>
                <img className={styles.PlayerFace} src={pics[props.name]}/>
                <div className={styles.PlayerStats}>
                    <div className={styles.TotalCardsContainer}>
                        <div>{totalCards}</div>
                        <div>{totalMcards}</div>
                    </div>
                    <hr style={{width: '100%', color: 'black'}}/>
                    <div>Punti vittoria: {props.points.vPoints}</div>
                    <div>Cavalieri: {props.points.knights}</div>
                </div>
        </div>
    )
};

export default OtherPlayer;