import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocalStorage } from 'react-use-storage';
import Header from '../01_components/Header';

import styles from '../02_styles/Login.module.css';
import { setPlayer } from '../04_store/00_actions/saxbarActions';

const Login = props => {
    const dispatch = useDispatch();
    const [storedPlayer, setStoredPlayer] = useLocalStorage('player', null);

    const [username, setUsername] = useState('fiumani');

    const dummyLoginHandler = () => {
        setStoredPlayer(username);
        dispatch(setPlayer(username));
    }

    return (
       <div className={styles.Login}>
           <Header login/>
           <div className={styles.LoginBox}>
                <div className={styles.LoginRow}>
                    <select value={username} onChange={e => setUsername(e.target.value)}>
                        <option>fiumani</option>
                        <option>gambone</option>
                        <option>grassi</option>
                        <option>santancini</option>
                    </select>
                </div>

                <div className={styles.LoginButton} onClick={dummyLoginHandler}>
                    <div>Entra</div>
                    <div>(pure co' la maghina in quarta fila)</div>
                </div>
           </div>
       </div> 
    )
};

export default Login;