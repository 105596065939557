import { Colors } from '../03_constants/Colors';

const getStreetCoords = {
	topLeft: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x - streetSize,
				y: start.y - streetSize * 1.8
			},
			third: {
				x: end.x - streetSize,
				y: end.y - streetSize * 1.8
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	},
	topRight: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x + streetSize,
				y: start.y - streetSize * 1.8
			},
			third: {
				x: end.x + streetSize,
				y: end.y - streetSize * 1.8
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	},
	left: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x - streetSize * 2,
				y: start.y
			},
			third: {
				x: end.x - streetSize * 2,
				y: end.y
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	},
	right: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x + streetSize * 2,
				y: start.y
			},
			third: {
				x: end.x + streetSize * 2,
				y: end.y
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	},
	bottomRight: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x + streetSize,
				y: start.y + streetSize * 1.8
			},
			third: {
				x: end.x + streetSize,
				y: end.y + streetSize * 1.8
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	},
	bottomLeft: (start, end, streetSize) => {
		return {
			first: {
				x: start.x,
				y: start.y
			},
			second: {
				x: start.x - streetSize,
				y: start.y + streetSize * 1.8
			},
			third: {
				x: end.x - streetSize,
				y: end.y + streetSize * 1.8
			},
			fourth: {
				x: end.x,
				y: end.y
			}
		};
	}
};

const getSettlementCoords = {
	topLeft: (x, y, streetSize) => {
		return {
			x: x - streetSize,
			y: y - (streetSize * Math.sqrt(3)) / 2.3,
			triangle: {
				oneX: x - streetSize * 2,
				oneY: y,
				twoX: x - streetSize,
				twoY: y - streetSize * 1.8,
				threeX: x,
				threeY: y
			}
		};
	},
	top: (x, y, streetSize) => {
		return {
			x,
			y: y - (streetSize * Math.sqrt(3)) / 1.5,
			triangle: {
				oneX: x,
				oneY: y,
				twoX: x - streetSize,
				twoY: y - streetSize * 1.8,
				threeX: x + streetSize,
				threeY: y - streetSize * 1.8
			}
		};
	},
	topRight: (x, y, streetSize) => {
		return {
			x: x + streetSize,
			y: y - (streetSize * Math.sqrt(3)) / 2,
			triangle: {
				oneX: x,
				oneY: y,
				twoX: x + streetSize,
				twoY: y - streetSize * 1.8,
				threeX: x + streetSize * 2,
				threeY: y
			}
		};
	},
	bottomRight: (x, y, streetSize) => {
		return {
			x: x + streetSize,
			y: y + (streetSize * Math.sqrt(3)) / 2.3,
			triangle: {
				oneX: x,
				oneY: y,
				twoX: x + streetSize,
				twoY: y + streetSize * 1.8,
				threeX: x + streetSize * 2,
				threeY: y
			}
		};
	},
	bottom: (x, y, streetSize) => {
		return {
			x,
			y: y + (streetSize * Math.sqrt(3)) / 1.5,
			triangle: {
				oneX: x,
				oneY: y,
				twoX: x - streetSize,
				twoY: y + streetSize * 1.8,
				threeX: x + streetSize * 1,
				threeY: y + streetSize * 1.8
			}
		};
	},
	bottomLeft: (x, y, streetSize) => {
		return {
			x: x - streetSize,
			y: y + (streetSize * Math.sqrt(3)) / 2,
			triangle: {
				oneX: x,
				oneY: y,
				twoX: x - streetSize * 2,
				twoY: y,
				threeX: x - streetSize,
				threeY: y + streetSize * 1.8
			}
		};
	}
};

const getHarborCoords = {
	topLeft: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX - (endX - startX) / 2,
			centerY: startY - (startY - endY) / 2,
			arcX: startX - (endX - startX) / 2 + streetSize * 3,
			arcY: startY - (startY - endY) / 2 - streetSize * 6
		};
	},
	topRight: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX + (endX - startX) / 2,
			centerY: startY - (startY - endY) / 2,
			arcX: startX + (endX - startX) / 2 + streetSize * 3,
			arcY: startY - (startY - endY) / 2 - streetSize * 6
		};
	},
	left: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX,
			centerY: startY + (endY - startY) / 2,
			arcX: startX - streetSize * 6,
			arcY: startY + (endY - startY) / 2
		};
	},
	right: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX,
			centerY: startY + (endY - startY) / 2,
			arcX: startX + streetSize * 6,
			arcY: startY + (endY - startY) / 2
		};
	},
	bottomLeft: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX,
			centerY: startY + (endY - startY) / 2,
			arcX: startX - streetSize * 6,
			arcY: startY - (startY - endY) / 2 + streetSize * 6
		};
	},
	bottomRight: (startX, endX, startY, endY, streetSize) => {
		return {
			centerX: startX + (endX - startX) / 2,
			centerY: startY + (endY - startY) / 2,
			arcX: startX + (endX - startX) / 2 + streetSize * 3,
			arcY: startY - (startY - endY) / 2 + streetSize * 6
		};
	}
};
// // // // // // // // // // // // // // // // // // // // // // // // // // // //
const drawBackground = (context, canvas) => {
	context.clearRect(0, 0, canvas.width, canvas.height);
	context.fillStyle = '#03ADEB';
	context.fillRect(0, 0, canvas.width, canvas.height);
};

const drawHex = (hexSize, offsetX, offsetY, context, hex, streetSize) => {
	let angle = 360 / 6;
	context.beginPath();

	let coords = { x: [], y: [], number: {} };

	for (let side = 0; side < 7; side++) {
		let a = (angle * side - 90) * (Math.PI / 180);
		let x = offsetX + hexSize * Math.cos(a);
		let y = offsetY + hexSize * Math.sin(a);
		context.lineTo(x, y);
		coords = { ...coords, x: [...coords.x, x], y: [...coords.y, y] };
	}

	context.stroke();
	context.fillStyle = Colors[hex.resource];
	context.fill();
	context.closePath();

	context.beginPath();
	context.arc(offsetX, offsetY, streetSize * 2.5, 0, 2 * Math.PI);
	context.stroke();
	context.fillStyle = hex.thief ? 'black' : Colors.numberTile;
	context.fill();

	if (hex.resource !== 'desert') {
		if ([6, 8].includes(hex.number)) context.fillStyle = 'red';
		else if (hex.thief) context.fillStyle = 'white';
		else context.fillStyle = 'black';
		context.font = `bold ${streetSize * 3}px courier`;
		context.fillText(
			hex.number,
			hex.number > 9 ? offsetX - streetSize * 1.7 : offsetX - streetSize,
			offsetY + streetSize
		);
	}

	coords = {
		...coords,
		number: {
			x: offsetX,
			y: offsetY,
			r: streetSize * 2.5,
			id: hex.id,
			number: hex.number,
			thief: hex.thief
		}
	};

	return coords;
};

const drawStreet = (context, streetCoords, player) => {
	context.beginPath();
	context.moveTo(streetCoords.first.x, streetCoords.first.y);
	context.lineTo(streetCoords.second.x, streetCoords.second.y);
	context.lineTo(streetCoords.third.x, streetCoords.third.y);
	context.lineTo(streetCoords.fourth.x, streetCoords.fourth.y);
	context.lineTo(streetCoords.first.x, streetCoords.first.y);
	context.stroke();
	context.fillStyle = Colors[player];
	context.fill();
};

const drawVillage = (context, streetSize, x, y, player) => {
	context.beginPath();
	context.arc(x, y, streetSize * 1.8, 0, 2 * Math.PI);
	context.stroke();
	context.fillStyle = Colors[player];
	context.fill();
	context.fillStyle = 'black';
};

const drawVillageTriangle = (context, village) => {
	context.beginPath();
	context.moveTo(village.oneX, village.oneY);
	context.lineTo(village.twoX, village.twoY);
	context.lineTo(village.threeX, village.threeY);
	context.lineTo(village.oneX, village.oneY);
	context.stroke();
	context.fillStyle = Colors.hover;
	context.fill();
	context.fillStyle = 'black';
};

const drawCity = (context, streetSize, x, y, player) => {
	const cityWidth = streetSize * 2;
	context.beginPath();
	context.moveTo(x - cityWidth, y - cityWidth);
	context.lineTo(x - cityWidth, y + cityWidth);
	context.lineTo(x + cityWidth, y + cityWidth);
	context.lineTo(x + cityWidth, y - cityWidth);
	context.lineTo(x - cityWidth, y - cityWidth);
	context.stroke();
	context.fillStyle = Colors[player];
	context.fill();
	context.fillStyle = 'black';
};

const drawHarbor = (
	context,
	streetSize,
	startX,
	startY,
	endX,
	endY,
	side,
	type
) => {
	context.save();
	const harborCoords = getHarborCoords[side](
		startX,
		endX,
		startY,
		endY,
		streetSize
	);
	context.beginPath();
	context.arc(
		harborCoords.arcX,
		harborCoords.arcY,
		streetSize * 1.8,
		0,
		2 * Math.PI
	);
	context.moveTo(harborCoords.arcX, harborCoords.arcY);
	context.lineTo(startX, startY);
	context.moveTo(harborCoords.arcX, harborCoords.arcY);
	context.lineTo(endX, endY);
	context.lineWidth = 3;
	context.stroke();
	context.fillStyle = Colors[type];
	context.fill();

	if (type === 'numberTile') {
		context.fillStyle = 'black';
		context.font = `bold ${streetSize * 2.5}px courier`;
		context.fillText(
			'?',
			harborCoords.arcX - streetSize / 1.4,
			harborCoords.arcY + streetSize / 1.5
		);
	}
	context.restore();
};
// // // // // // // // // // // // // // // // // // // // // // // // // // // //
const drawHexs = (board, hexSize, offsetX, offsetY, context, streetSize) => {
	let coords = [];
	board.forEach((hex, i) => {
		coords = [
			...coords,
			drawHex(
				hexSize,
				offsetX + hexSize * (hex.rowIdx + hex.shiftX) * 2,
				offsetY + hexSize * hex.shiftY,
				context,
				hex,
				streetSize
			)
		];
	});

	drawHarbor(
		context,
		streetSize,
		coords[0].x[5],
		coords[0].y[5],
		coords[0].x[0],
		coords[0].y[0],
		'topLeft',
		'numberTile'
	);
	drawHarbor(
		context,
		streetSize,
		coords[1].x[0],
		coords[1].y[0],
		coords[1].x[1],
		coords[1].y[1],
		'topRight',
		'wheat'
	);
	drawHarbor(
		context,
		streetSize,
		coords[3].x[4],
		coords[3].y[4],
		coords[3].x[5],
		coords[3].y[5],
		'left',
		'wood'
	);
	drawHarbor(
		context,
		streetSize,
		coords[6].x[0],
		coords[6].y[0],
		coords[6].x[1],
		coords[6].y[1],
		'topRight',
		'stone'
	);
	drawHarbor(
		context,
		streetSize,
		coords[11].x[1],
		coords[11].y[1],
		coords[11].x[2],
		coords[11].y[2],
		'right',
		'numberTile'
	);
	drawHarbor(
		context,
		streetSize,
		coords[12].x[4],
		coords[12].y[4],
		coords[12].x[5],
		coords[12].y[5],
		'left',
		'brick'
	);
	drawHarbor(
		context,
		streetSize,
		coords[15].x[2],
		coords[15].y[2],
		coords[15].x[3],
		coords[15].y[3],
		'bottomRight',
		'sheep'
	);
	drawHarbor(
		context,
		streetSize,
		coords[16].x[3],
		coords[16].y[3],
		coords[16].x[4],
		coords[16].y[4],
		'bottomLeft',
		'numberTile'
	);
	drawHarbor(
		context,
		streetSize,
		coords[17].x[2],
		coords[17].y[2],
		coords[17].x[3],
		coords[17].y[3],
		'bottomRight',
		'numberTile'
	);

	return coords;
};

const drawStreets = (context, coords, streetSize, streets) => {
	const streetsCoords = streets.map((street, idx) => {
		const streetCoords = getStreetCoords[street.side](
			{
				x: coords.hex[street.hex].x[street.start],
				y: coords.hex[street.hex].y[street.start]
			},
			{
				x: coords.hex[street.hex].x[street.end],
				y: coords.hex[street.hex].y[street.end]
			},
			streetSize
		);
		if (street.player !== 'board')
			drawStreet(context, streetCoords, street.player);
		return { ...streetCoords, id: idx + 1 };
	});

	return streetsCoords;
};

const drawSettlements = (context, streetSize, coords, settlements) => {
	const settlementsCoords = settlements.map((settlement, idx) => {
		const settlementCoords = getSettlementCoords[settlement.side](
			coords.hex[settlement.hex].x[settlement.corner],
			coords.hex[settlement.hex].y[settlement.corner],
			streetSize
		);

		if (settlement.type === 'village')
			drawVillage(
				context,
				streetSize,
				settlementCoords.x,
				settlementCoords.y,
				settlement.player
			);
		else if (settlement.type === 'city')
			drawCity(
				context,
				streetSize,
				settlementCoords.x,
				settlementCoords.y,
				settlement.player
			);
		return { ...settlementCoords, id: idx + 1 };
	});

	return settlementsCoords;
};

const drawHover = (context, hoverData, boardData) => {
	hoverData.streets.forEach(street => {
		drawStreet(context, street, 'hover');
	});

	hoverData.settlements.forEach(settlement => {
		drawVillageTriangle(context, settlement.triangle);
	});
};

// // // // // // // // // // // // // // // // // // // // // // // // // // // //
export const drawBoard = (
	boardData,
	context,
	canvas,
	board,
	streets,
	settlements,
	hoverData
) => {
	drawBackground(context, canvas);

	context.save();

	let coords = { hex: [], streets: [], settlements: [] };
	coords = {
		...coords,
		hex: drawHexs(
			board,
			boardData.hexSize,
			boardData.offsetX,
			boardData.offsetY,
			context,
			boardData.streetSize
		)
	};

	coords = {
		...coords,
		streets: drawStreets(context, coords, boardData.streetSize, streets)
	};

	coords = {
		...coords,
		settlements: drawSettlements(
			context,
			boardData.streetSize,
			coords,
			settlements
		)
	};

	drawHover(context, hoverData, boardData);

	return coords;
};
