import brick from '../05_assets/images/cards/brick.png';
import wheat from '../05_assets/images/cards/wheat.png';
import sheep from '../05_assets/images/cards/sheep.png';
import stone from '../05_assets/images/cards/stone.png';
import wood from '../05_assets/images/cards/wood.png';
import mcard from '../05_assets/images/cards/mcard.png';
import { Colors } from '../03_constants/Colors';

import styles from '../02_styles/Card.module.css';

const pics = {brick, wheat, sheep, stone, wood, mcard}

const Card = props => {
    return (
        <img
            style={{backgroundColor: Colors[props.type]}}
            className={styles.Card}
            onClick={() => props.click(props.type)}
            onDrop={props.drop}
            onDragOver={props.dragOver}
            src={pics[props.type]}/>
    )
};

export default Card;