export const ActionTypes = {
    SET_SOCKET: 'SET_SOCKET',
    SET_PLAYER: 'SET_PLAYER',
    SET_DATABASE_DATA: 'SET_DATABASE_DATA',
    UPDATE_DATABASE_DATA: 'UPDATE_DATABASE_DATA',
    SELECT_CARD: 'SELECT_CARD',
    DESELECT_CARD: 'DESELECT_CARD',
    ADD_PLAYER_CARD: 'ADD_PLAYER_CARD',
    ADD_PLAYER_MCARD: 'ADD_PLAYER_MCARD',
    UPDATE_CARDS: 'UPDATE_CARDS',
    SELECT_MCARD: 'SELECT_MCARD',
    UNLOCK_MCARDS: 'UNLOCK_MCARDS'
}