import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { useLocalStorage } from 'react-use-storage';
import { io } from 'socket.io-client';

import styles from './02_styles/App.module.css';
import Login from './00_pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayer, setSocket } from './04_store/00_actions/saxbarActions';
import Home from './00_pages/Home';
import Pomezia from './00_pages/Pomezia';
import { useEffect } from 'react';

// const ip = 'http://saxbar.servebeer.com';
const ip = 'https://saxbar.servebeer.com';

const App = () => {
  const dispatch = useDispatch();
  const player = useSelector(state => state.saxbar.player);
  const [storedPlayer] = useLocalStorage('player', null);
  if (storedPlayer) dispatch(setPlayer(storedPlayer));

  useEffect(
    () => {
      const socket = io(ip, {transports: ['websocket']});
      dispatch(setSocket(socket));
    },
    []
  )

  return (
    <div className={styles.App}>
      <Router>
        {player ? (
          <Switch>
            <Route path='/pomezia'>
              <Pomezia />
            </Route>

            <Route path='/capannelle'>

            </Route>

            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        ) : <Login />}
        
      </Router>
    </div>
  );
}

export default App;
