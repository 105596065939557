import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Board from '../01_components/Board';

import Card from '../01_components/Card';

import CardSquare from '../01_components/CardSquare';
import Dice from '../01_components/Dice';
import Header from '../01_components/Header';
import Jitsi from '../01_components/Jitsi';
import Mcard from '../01_components/Mcard';
import ModalPomezia from '../01_components/ModalPomezia';
import OtherPlayer from '../01_components/OtherPlayer';

import styles from '../02_styles/Pomezia.module.css';
import { addPlayerCards, addPlayerMcards, setDatabaseData, unlockMcards, updateCards, updateDatabaseData } from '../04_store/00_actions/pomeziaActions';

const Pomezia = props => {
    const dispatch = useDispatch();
    const { player, socket, otherPlayers} = useSelector(state => state.saxbar);
    const databaseData = useSelector(state => state.pomezia.databaseData);
    const lockedMcards = useSelector(state => state.pomezia.lockedMcards);

    const [jitsi, setJitsi] = useState(false);

    const [showModal, setShowModal] = useState(false);
    
    const endOfTerminal = useRef(null);

    useEffect(
        () => {
            if (socket) {
                socket.emit('GET_DATABASE', player);

                socket.on('GOT_DATABASE', databaseData => {
                    dispatch(setDatabaseData(databaseData));
                }); 

                socket.on('DICES_SET', (newDices, logs, diceHistory) => {
                    dispatch(unlockMcards());
                    dispatch(updateDatabaseData({
                        dices: newDices,
                        logs,
                        diceHistory
                    }))
                });

                socket.on('UPDATE_LOGS', logs => dispatch(updateDatabaseData({logs})));

                socket.on('GOT_CARD', newCards => dispatch(addPlayerCards(newCards.playerCards)));

                socket.on('GOT_TOTAL_CARDS', newDatabaseData => dispatch(updateDatabaseData(
                    {
                        totalCards: newDatabaseData.totalCards,
                        logs: newDatabaseData.logs
                    }
                )));

                socket.on('GOT_MCARD', newMcards => {
                    dispatch(addPlayerMcards(newMcards.playerMcards));
                });

                socket.on('GOT_TOTAL_MCARDS', newDatabaseData => dispatch(updateDatabaseData(
                    {
                        totalMcards: newDatabaseData.totalMcards,
                        logs: newDatabaseData.logs,
                        points: newDatabaseData.points
                    }
                )));

                socket.on('CARDS_FINISHED', newLogs => dispatch(updateDatabaseData({logs: newLogs.logs})));

                socket.on('CARDS_GIVEN', (newCards) => {
                    if (newCards.player === player || newCards.dstPlayer === player) {
                        dispatch(updateCards(newCards[player].cards))
                    };
                    dispatch(updateDatabaseData(
                        {
                            totalCards: newCards[player].totalCards,
                            logs: newCards.logs
                        }
                    ))

                });

                socket.on('THIEF_MOVED', newBoard => {
                    dispatch(updateDatabaseData({board: newBoard.board}))
                });

                socket.on('STREETS_UPDATED', streets => {
                    dispatch(updateDatabaseData(streets));
                });

                socket.on('SETTLEMENTS_UPDATED', settlements => {
                    dispatch(updateDatabaseData(settlements));
                });

                socket.on('DICE_HISTORY_RESET', (diceHistory, logs) => {
                    dispatch(updateDatabaseData(diceHistory, logs));
                });
            }
            return () => socket?.removeAllListeners();
        },
        [socket, dispatch]
    );

    useEffect(
        () => {
            endOfTerminal.current?.scrollIntoView({behaviour: 'smooth'});
        },
        [databaseData?.logs, jitsi]
    )

    const throwDicesHandler = () => {
        const newLeft = Math.floor(Math.random() * 6) + 1;
        const newRight = Math.floor(Math.random() * 6) + 1;
        socket.emit('SET_DICES', {left: newLeft, right: newRight, thrownBy: player});
    };

    const clearLogsHandler = () => {
        if (window.confirm('Clear logs?')) socket.emit('CLEAR_LOGS');
    };

    const getCardHandler = cardType => {
        if (cardType !== 'mcard') socket.emit('GET_CARD', player, cardType);
        if (cardType === 'mcard') socket.emit('GET_MCARD', player);
    }

    const dragoverHandler = e => {
        e.preventDefault();
    }

    const dropHandler = (e, dstPlayer) => {
        e.preventDefault();
        const src = e.dataTransfer.getData('src');
        const droppedCards = Object.keys(databaseData.playerCards).map(cardType => {
            return {
                type: cardType,
                amount: databaseData.playerCards[cardType].filter(card => card.selected).length
            }
        });

        if (src === 'playerCard' && dstPlayer !== 'pack') socket.emit('GIVE_CARDS', droppedCards, player, dstPlayer);
        else if (src === 'playerCard' && dstPlayer === 'pack') {
            socket.emit('GIVE_CARDS', droppedCards, player, dstPlayer);
        }
    }

    const dropMcardHandler = e => {
        e.preventDefault();
        const src = e.dataTransfer.getData('src');
        const droppedMcards = databaseData.playerMcards.filter(mcard => mcard.selected)
        const freeDroppedMcards = droppedMcards.filter(mcard => !lockedMcards.map(mc => mc.id).includes(mcard.id))

        if (src === 'playerMcard' && freeDroppedMcards.length > 0) socket.emit('USE_MCARDS', freeDroppedMcards, player);
    };

    const clickOtherPlayerHandler = (e, srcPlayer) => {
        socket.emit('STEAL_CARD', {srcPlayer, dstPlayer: player});
    };

    const switchModalHandler = () => {
        setShowModal(!showModal);
    }

    return (
        <div className={styles.Pomezia}>
            {showModal && <ModalPomezia database={databaseData} closeModal={switchModalHandler} />}
            <Header title='I coloni di Pomezia' showHomeButton openModal={switchModalHandler}/>
            {!databaseData ? <div className={styles.LoadingDb}>Loading database...</div> : (
                <div className={styles.Body}>
                    <div className={styles.PlayerPack}>
                        <div className={styles.PlayerCards}>
                            {
                                databaseData.playerCards.wood.map(card => <CardSquare key={card.id} id={card.id} selected={card.selected} type='wood'/>)
                            }
                            {
                                databaseData.playerCards.brick.map(card => <CardSquare key={card.id} id={card.id} selected={card.selected} type='brick'/>)
                            }
                            {
                                databaseData.playerCards.wheat.map(card => <CardSquare key={card.id} id={card.id} selected={card.selected} type='wheat'/>)
                            }
                            {
                                databaseData.playerCards.sheep.map(card => <CardSquare key={card.id} id={card.id} selected={card.selected} type='sheep'/>)
                            }
                            {
                                databaseData.playerCards.stone.map(card => <CardSquare key={card.id} id={card.id} selected={card.selected} type='stone'/>)
                            }
                        </div>
                        <div className={styles.PlayerMCards}>
                            {
                                databaseData.playerMcards.map(mcard => <Mcard key={mcard.id} id={mcard.id} type='mcard' description={mcard.description} selected={mcard.selected}/>)
                            }
                        </div>
                    </div>

                    <div className={styles.Table}>
                        <div className={styles.OtherPlayers}>
                            <OtherPlayer
                                click={clickOtherPlayerHandler} 
                                dragOver={dragoverHandler}
                                drop={dropHandler}
                                name={otherPlayers.two}
                                points={databaseData.points.filter(player => player.player === otherPlayers.two)[0]}
                                totalCards={databaseData.totalCards.filter(pack => pack.player === otherPlayers.two)[0].totalCards}
                                totalMcards={databaseData.totalMcards.filter(pack => pack.player === otherPlayers.two)[0].totalMcards}/>
                            <OtherPlayer
                                click={clickOtherPlayerHandler} 
                                dragOver={dragoverHandler}
                                drop={dropHandler}
                                name={otherPlayers.three}
                                points={databaseData.points.filter(player => player.player === otherPlayers.three)[0]}
                                totalCards={databaseData.totalCards.filter(pack => pack.player === otherPlayers.three)[0].totalCards}
                                totalMcards={databaseData.totalMcards.filter(pack => pack.player === otherPlayers.three)[0].totalMcards}/>
                            <OtherPlayer
                                click={clickOtherPlayerHandler} 
                                dragOver={dragoverHandler}
                                drop={dropHandler}
                                name={otherPlayers.four}
                                points={databaseData.points.filter(player => player.player === otherPlayers.four)[0]}
                                totalCards={databaseData.totalCards.filter(pack => pack.player === otherPlayers.four)[0].totalCards}
                                totalMcards={databaseData.totalMcards.filter(pack => pack.player === otherPlayers.four)[0].totalMcards}
                                last/>

                        </div>
                        <div className={styles.Board}>
                            <div className={styles.DicesBox}>
                               <Dice
                                side='left'
                                click={throwDicesHandler} />
                               <Dice
                                side='right'
                                click={throwDicesHandler} />
                            </div>

                            <div className={styles.CardsBoxLeft}>
                                <Card click={getCardHandler} type='wood' dragOver={dragoverHandler} drop={e => dropHandler(e, 'pack')} />
                                <Card click={getCardHandler} type='brick' dragOver={dragoverHandler} drop={e => dropHandler(e, 'pack')} />
                                <Card click={getCardHandler} type='wheat' dragOver={dragoverHandler} drop={e => dropHandler(e, 'pack')} />
                            </div>
                            <div className={styles.CardsBoxRight}>
                                <Card click={getCardHandler} type='sheep' dragOver={dragoverHandler} drop={e => dropHandler(e, 'pack')} />
                                <Card click={getCardHandler} type='stone' dragOver={dragoverHandler} drop={e => dropHandler(e, 'pack')} />
                                <Card click={getCardHandler} type='mcard' dragOver={dragoverHandler} drop={e => dropMcardHandler(e)} />
                            </div>

                            <Board />
                        </div>
                    </div>

                    <div className={styles.Croupier}>
                        <div className={!jitsi ? styles.LogsContainer : styles.LogsContainerShrunken}>
                            <div className={styles.ClearLogsButton} onClick={clearLogsHandler}>X</div>

                            <div className={styles.LogsTerminal}>
                                {databaseData.logs.map(log => <div className={styles.Log} key={log.id}>{'cP> '}{log.message}</div>)}
                                <div  ref={endOfTerminal}/>
                            </div>
                            
                        </div>

                        <div className={jitsi ? styles.JitsiContainer : styles.JitsiContainerShrunken}>
                            <Jitsi enter={() => setJitsi(!jitsi)} exit={() => setJitsi(!jitsi)} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default Pomezia;