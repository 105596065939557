import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
	FiArrowUpCircle,
	FiArrowDownCircle,
	FiArrowLeftCircle,
	FiArrowRightCircle,
	FiZoomIn,
	FiZoomOut
} from 'react-icons/fi';

import styles from '../02_styles/Board.module.css';

import { drawBoard } from '../06_utils/BoardHandler';

// // // // // //
const Board = props => {
	const boardRef = useRef(null);
	const databaseData = useSelector(state => state.pomezia.databaseData);
	const { player, socket } = useSelector(state => state.saxbar);
	const [boardData, setBoardData] = useState({
		hexSize: 50,
		streetSize: (50 / 100) * 13.33333,
		offsetX: 350,
		offsetY: 200
	});
	const [coords, setCoords] = useState([]);
	const [hoverData, setHoverData] = useState({
		streets: [],
		settlements: [],
		numbers: []
	});

	const clickHandler = (x, y) => {
		coords.hex.forEach(hex => {
			if (
				Math.sqrt(
					(x - hex.number.x) * (x - hex.number.x) +
						(y - hex.number.y) * (y - hex.number.y)
				) < hex.number.r
			) {
				socket.emit('NUMBER_CLICKED', hex.number.id);
			}
		});

		coords.streets.forEach(street => {
			let i,
				j,
				click = false;
			let vertx = [
				street.first.x,
				street.second.x,
				street.third.x,
				street.fourth.x
			];
			let verty = [
				street.first.y,
				street.second.y,
				street.third.y,
				street.fourth.y
			];
			for (i = 0, j = 3; i < 4; j = i++) {
				if (
					verty[i] > y != verty[j] > y &&
					x <
						((vertx[j] - vertx[i]) * (y - verty[i])) / (verty[j] - verty[i]) +
							vertx[i]
				) {
					click = !click;
				}
			}
			if (click) socket.emit('STREET_CLICKED', { streetId: street.id, player });
		});

		coords.settlements.forEach(settlement => {
			let i,
				j,
				click = false;
			let vertx = [
				settlement.triangle.oneX,
				settlement.triangle.twoX,
				settlement.triangle.threeX
			];
			let verty = [
				settlement.triangle.oneY,
				settlement.triangle.twoY,
				settlement.triangle.threeY
			];

			for (i = 0, j = 2; i < 3; j = i++) {
				if (
					verty[i] > y != verty[j] > y &&
					x <
						((vertx[j] - vertx[i]) * (y - verty[i])) / (verty[j] - verty[i]) +
							vertx[i]
				) {
					click = !click;
				}
			}
			if (click)
				socket.emit('SETTLEMENT_CLICKED', {
					settlementId: settlement.id,
					player
				});
		});
	};

	const hoverHandler = (x, y) => {
		let hoverStreets = [];
		let hoverSettlements = [];

		coords.streets.forEach(street => {
			let i,
				j,
				click = false;
			let vertx = [
				street.first.x,
				street.second.x,
				street.third.x,
				street.fourth.x
			];
			let verty = [
				street.first.y,
				street.second.y,
				street.third.y,
				street.fourth.y
			];
			for (i = 0, j = 3; i < 4; j = i++) {
				if (
					verty[i] > y != verty[j] > y &&
					x <
						((vertx[j] - vertx[i]) * (y - verty[i])) / (verty[j] - verty[i]) +
							vertx[i]
				) {
					click = !click;
				}
			}
			if (click) hoverStreets.push(street);
		});

		coords.settlements.forEach(settlement => {
			let i,
				j,
				click = false;
			let vertx = [
				settlement.triangle.oneX,
				settlement.triangle.twoX,
				settlement.triangle.threeX
			];
			let verty = [
				settlement.triangle.oneY,
				settlement.triangle.twoY,
				settlement.triangle.threeY
			];

			for (i = 0, j = 2; i < 3; j = i++) {
				if (
					verty[i] > y != verty[j] > y &&
					x <
						((vertx[j] - vertx[i]) * (y - verty[i])) / (verty[j] - verty[i]) +
							vertx[i]
				) {
					click = !click;
				}
			}
			if (click) hoverSettlements.push(settlement);
		});

		if (
			hoverStreets.length !== hoverData.streets.length ||
			hoverSettlements.length !== hoverData.settlements.length ||
			hoverStreets.length > 0
		) {
			console.log(new Date());
			setHoverData(prev => {
				return { streets: hoverStreets, settlements: hoverSettlements };
			});
		}

		if (hoverStreets.length > 0 || hoverSettlements.length > 0) {
			boardRef.current.style.cursor = 'pointer';
		} else {
			boardRef.current.style.cursor = 'default';
		}
	};

	useEffect(() => {
		const canvas = boardRef.current;
		const context = canvas.getContext('2d');

		drawBoard(
			boardData,
			context,
			canvas,
			databaseData.board,
			databaseData.streets,
			databaseData.settlements,
			hoverData
		);
	}, [hoverData]);

	useEffect(() => {
		const canvas = boardRef.current;
		const context = canvas.getContext('2d');

		const drawBoardHandler = canvas => {
			canvas.style.width = '100%';
			canvas.style.height = '100%';
			canvas.width = canvas.offsetWidth;
			canvas.height = canvas.offsetHeight;
			const coords = drawBoard(
				boardData,
				context,
				canvas,
				databaseData.board,
				databaseData.streets,
				databaseData.settlements,
				hoverData
			);
			setCoords(coords);
		};

		drawBoardHandler(canvas);

		window.addEventListener('resize', e => drawBoardHandler(canvas));
	}, [boardData, databaseData]);

	return (
		<div className={styles.Board}>
			<div className={styles.CanvasContainer}>
				<canvas
					ref={boardRef}
					onClick={e =>
						clickHandler(
							e.clientX - e.target.getBoundingClientRect().left,
							e.clientY - e.target.getBoundingClientRect().top
						)
					}
					onMouseMove={e =>
						hoverHandler(
							e.clientX - e.target.getBoundingClientRect().left,
							e.clientY - e.target.getBoundingClientRect().top
						)
					}
				/>

				<div
					className={styles.MinusButton}
					onClick={() =>
						setBoardData({
							...boardData,
							hexSize: boardData.hexSize - 5,
							streetSize: boardData.streetSize - (5 / 100) * 13.33333
						})
					}
				>
					<FiZoomOut style={{ width: '30px', height: '30px' }} />
				</div>
				<div
					className={styles.PlusButton}
					onClick={() =>
						setBoardData({
							...boardData,
							hexSize: boardData.hexSize + 5,
							streetSize: boardData.streetSize + (5 / 100) * 13.33333
						})
					}
				>
					<FiZoomIn style={{ width: '30px', height: '30px' }} />
				</div>

				<div
					className={styles.LeftButton}
					onClick={() =>
						setBoardData({ ...boardData, offsetX: boardData.offsetX - 30 })
					}
				>
					<FiArrowLeftCircle style={{ width: '30px', height: '30px' }} />
				</div>
				<div
					className={styles.RightButton}
					onClick={() =>
						setBoardData({ ...boardData, offsetX: boardData.offsetX + 30 })
					}
				>
					<FiArrowRightCircle style={{ width: '30px', height: '30px' }} />
				</div>
				<div
					className={styles.UpButton}
					onClick={() =>
						setBoardData({ ...boardData, offsetY: boardData.offsetY - 30 })
					}
				>
					<FiArrowUpCircle style={{ width: '30px', height: '30px' }} />
				</div>
				<div
					className={styles.DownButton}
					onClick={() =>
						setBoardData({ ...boardData, offsetY: boardData.offsetY + 30 })
					}
				>
					<FiArrowDownCircle style={{ width: '30px', height: '30px' }} />
				</div>
			</div>
		</div>
	);
};

export default Board;
