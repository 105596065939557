import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'react-use-storage';
import styles from '../02_styles/ModalPomezia.module.css';
import { setPlayer } from '../04_store/00_actions/saxbarActions';

const ModalPomezia = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const player = useSelector(state => state.saxbar.player);
    const socket = useSelector(state => state.saxbar.socket);
    const[_, __, removeStoredPlayer] = useLocalStorage('player');

    const playerPoints = props.database.points.filter(p => p.player === player)[0];

    const logoutHandler = () => {
        removeStoredPlayer();
        history.push('/');
        dispatch(setPlayer(null));
    }

    const shuffleHandler = shuffleStyle => {
        if (window.confirm('Rimischio tutto?')) socket.emit('SHUFFLE', player, shuffleStyle);
        props.closeModal();
    }

    const resetDiceHistoryHandler = () => {
        if (window.confirm('Resetta statistiche dadi?')) socket.emit('RESET_DICES_HISTORY');
        props.closeModal();
    }
    console.log(props.database)
    return (
        <div className={styles.ModalPomezia}>
            <div className={styles.PlayerStats}>
                Punti vittoria: {playerPoints.vPoints}
            </div>
            <div className={styles.PlayerStats}>
                Cavalieri: {playerPoints.knights}
            </div>
            <hr style={{width: '70%', margin: '40px 0 20px 0'}} />
            <div className={styles.DicesStats}>
                <p style={{marginTop: '0'}}>Statistiche dadi:</p>
                {props.database.diceHistory.map(number => <div key={number.number}>{number.number} {'---->'} {number.timesThrown}</div>)}
            </div>
                <div className={styles.ResetButton} onClick={resetDiceHistoryHandler}>reset</div>
            <hr style={{width: '70%', margin: '20px 0 20px 0'}} />
            <div className={styles.ShuffleButtonsBox}>
                <div className={styles.ShuffleButton} onClick={() => shuffleHandler('random')}>! Rimischia (a caso) !</div>
                <div className={styles.ShuffleButton} onClick={() => shuffleHandler('scientific')}>! Rimischia (scientifico) !</div>
            </div>
            
            <div className={styles.LogoutButton} onClick={logoutHandler}>Log-out</div>
        </div>
    )
};

export default ModalPomezia;