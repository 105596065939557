import { ActionTypes } from "../../03_constants/ActionTypes";

import uuid from 'react-uuid';

const pomezia = {
    databaseData: null,
    lockedMcards: []
};

const pomeziaReducer = (state = pomezia, action) => {
    const parsePlayerCards = cards => {
        return {
            wood: Array(cards.wood - state.databaseData.playerCards.wood.length).fill().map(card => {return {id: uuid(), selected: false}}),
            brick: Array(cards.brick - state.databaseData.playerCards.brick.length).fill().map(card => {return {id: uuid(), selected: false}}),
            wheat: Array(cards.wheat - state.databaseData.playerCards.wheat.length).fill().map(card => {return {id: uuid(), selected: false}}),
            sheep: Array(cards.sheep - state.databaseData.playerCards.sheep.length).fill().map(card => {return {id: uuid(), selected: false}}),
            stone: Array(cards.stone - state.databaseData.playerCards.stone.length).fill().map(card => {return {id: uuid(), selected: false}})
        };
    };

    const createPlayerCards = cards => {
        return {
            wood: Array(cards.wood).fill().map(card => {return {id: uuid(), selected: false}}),
            brick: Array(cards.brick).fill().map(card => {return {id: uuid(), selected: false}}),
            wheat: Array(cards.wheat).fill().map(card => {return {id: uuid(), selected: false}}),
            sheep: Array(cards.sheep).fill().map(card => {return {id: uuid(), selected: false}}),
            stone: Array(cards.stone).fill().map(card => {return {id: uuid(), selected: false}})
        };
    };

    const createResourceCards = number => {
        return Array(number).fill().map(card => {return {id: uuid(), selected: false}});
    };

    const createPlayerMcards = mcards => {
        return mcards.map(mcard => {return {id: mcard.id, description: mcard.description, selected: false}});
    };

    const filterPlayerCards = cards => {
        const filteredPlayerCards = {
            brick: [],
            sheep: [],
            stone: [],
            wheat: [],
            wood: []
        }
        const resources = ['brick', 'sheep', 'stone', 'wheat', 'wood']
        resources.forEach(resource => {
            if (cards[resource] === state.databaseData.playerCards[resource].length) {
                filteredPlayerCards[resource] = state.databaseData.playerCards[resource];
            } else if (cards[resource] > state.databaseData.playerCards[resource].length) {
                filteredPlayerCards[resource] = state.databaseData.playerCards[resource].concat(createResourceCards(cards[resource] - state.databaseData.playerCards[resource].length))
            } else if (cards[resource] < state.databaseData.playerCards[resource].length) {
                filteredPlayerCards[resource] = createResourceCards(cards[resource])
            }
        });
        return filteredPlayerCards;

        
    }

    switch (action.type) {
        case ActionTypes.SET_DATABASE_DATA:
            const playerCards = createPlayerCards(action.databaseData.playerCards);
            const playerMcards = createPlayerMcards(action.databaseData.playerMcards);

            return {
                ...state,
                databaseData: {...action.databaseData, playerCards, playerMcards}
            };

        case ActionTypes.UPDATE_DATABASE_DATA:
            return {...state, databaseData: {...state.databaseData, ...action.newDatabaseData}};

        case ActionTypes.ADD_PLAYER_CARD:
            const newPlayerCards = parsePlayerCards(action.newPlayerCards);
            return {...state, databaseData: {...state.databaseData, playerCards: {
                wood: state.databaseData.playerCards.wood.concat(newPlayerCards.wood),
                brick: state.databaseData.playerCards.brick.concat(newPlayerCards.brick),
                wheat: state.databaseData.playerCards.wheat.concat(newPlayerCards.wheat),
                sheep: state.databaseData.playerCards.sheep.concat(newPlayerCards.sheep),
                stone: state.databaseData.playerCards.stone.concat(newPlayerCards.stone)
            }}};

        case ActionTypes.ADD_PLAYER_MCARD:
            const newPlayerMcards = createPlayerMcards(action.newPlayerMcards);
            return {
                ...state,
                databaseData: {...state.databaseData, playerMcards: newPlayerMcards},
                lockedMcards: [
                    ...state.lockedMcards,
                    ...action.newPlayerMcards.filter(
                        mcard => !state.databaseData.playerMcards.map(pMcard => pMcard.id).includes(mcard.id)
                    )
                ]
            };

        case ActionTypes.SELECT_CARD:
            return {...state, databaseData: {
                ...state.databaseData,
                playerCards: {
                    ...state.databaseData.playerCards,
                    [action.cardType]: state.databaseData.playerCards[action.cardType].map(
                        card => card.id === action.cardId ? {...card, selected: !card.selected} : card
                    )
                }
            }};

        case ActionTypes.SELECT_MCARD:
            return {...state, databaseData: {
                ...state.databaseData,
                playerMcards: state.databaseData.playerMcards.map(
                    mcard => mcard.id === action.mcardId ? {...mcard, selected: !mcard.selected} : mcard
                )
            }};

        case ActionTypes.UPDATE_CARDS:
            const updatedPlayerCards = filterPlayerCards(action.newCards);
            const updatedPlayerCardsOld = createPlayerCards(action.newCards);

            return {...state, databaseData: {...state.databaseData, playerCards: updatedPlayerCards}};

        case ActionTypes.UNLOCK_MCARDS:
            return {...state, lockedMcards: []};
    
        default:
            return state;
    }
}

export default pomeziaReducer;