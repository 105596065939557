import React, { useState } from 'react';

import { Jutsu } from 'react-jutsu';
import { useSelector } from 'react-redux';

import styles from '../02_styles/Jitsi.module.css';
import { JaaSMeeting } from '@jitsi/react-sdk';

const Jitsi = props => {
	const player = useSelector(state => state.saxbar.player);
	const [call, setCall] = useState(false);

	const handleClick = event => {
		event.preventDefault();
		setCall(true);
	};

	const enterHandler = () => {
		setCall(true);
		props.enter();
	};

	const exitHandler = () => {
		setCall(false);
		props.exit();
	};

	return call ? (
		// <Jutsu
		// 	id='jaas-container'
		// 	roomName='i-coloni-di-pomezia'
		// 	displayName={player}
		// 	onMeetingEnd={exitHandler}
		// 	loadingComponent={<p>loading ...</p>}
		// 	errorComponent={<p>Oops, something went wrong</p>}
		// 	containerStyles={{ width: '100%', height: '100%' }}
		// />
		<JaaSMeeting
			appId={'vpaas-magic-cookie-c886e7263fae4522bc592022fae73cc4'}
			roomName={'PomeziaBeautifulTown'}
			getIFrameRef={node => (node.style.height = '100%')}
		/>
	) : (
		<div className={styles.EnterBox}>
			<div
				className={styles.EnterButton}
				onClick={enterHandler}
			>
				Jitsi
			</div>
		</div>
	);
};

export default Jitsi;
