import { ActionTypes } from "../../03_constants/ActionTypes";

export const setSocket = socket => {
    return {
        type: ActionTypes.SET_SOCKET,
        socket
    }
};

export const setPlayer = player => {
    return {
        type: ActionTypes.SET_PLAYER,
        player
    }
};