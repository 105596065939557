import { ActionTypes } from "../../03_constants/ActionTypes";

const getOtherPlayers = (name) => {
    const playersMap = {
        fiumani: {two: 'gambone', three: 'grassi', four: 'santancini'},
        gambone: {two: 'grassi', three: 'santancini', four: 'fiumani'},
        grassi: {two: 'santancini', three: 'fiumani', four: 'gambone'},
        santancini: {two: 'fiumani', three: 'gambone', four: 'grassi'}
    };
    return playersMap[name];
}

const saxbar = {
    socket: null,
    player: null,
    otherPlayers: null
}

const saxbarReducer = (state = saxbar, action) => {
    switch (action.type) {
        case ActionTypes.SET_SOCKET:
            return {...state, socket: action.socket};

        case ActionTypes.SET_PLAYER:
            return {...state, player: action.player, otherPlayers: getOtherPlayers(action.player)};
    
        default:
            return state;
    }
}

export default saxbarReducer;