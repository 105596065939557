import { ActionTypes } from "../../03_constants/ActionTypes";

export const setDatabaseData = databaseData => {
    return {
        type: ActionTypes.SET_DATABASE_DATA,
        databaseData
    }
};

export const updateDatabaseData = newDatabaseData => {
    return {
        type: ActionTypes.UPDATE_DATABASE_DATA,
        newDatabaseData
    }
};

export const addPlayerCards = newPlayerCards => {
    return {
        type: ActionTypes.ADD_PLAYER_CARD,
        newPlayerCards
    }
};

export const addPlayerMcards = newPlayerMcards => {
    return {
        type: ActionTypes.ADD_PLAYER_MCARD,
        newPlayerMcards
    }
};

export const selectCard = (cardId, cardType) => {
    return {
        type: ActionTypes.SELECT_CARD,
        cardId,
        cardType
    }
};

export const selectMcard = mcardId => {
    return {
        type: ActionTypes.SELECT_MCARD,
        mcardId
    }
};

export const updateCards = newCards => {
    return {
        type: ActionTypes.UPDATE_CARDS,
        newCards
    }
};

export const unlockMcards = () => {
    return {type: ActionTypes.UNLOCK_MCARDS};
};