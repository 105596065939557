import React from 'react';
import { useSelector } from 'react-redux';

import one from '../05_assets/images/dice/1.png';
import two from '../05_assets/images/dice/2.png';
import three from '../05_assets/images/dice/3.png';
import four from '../05_assets/images/dice/4.png';
import five from '../05_assets/images/dice/5.png';
import six from '../05_assets/images/dice/6.png';

import styles from '../02_styles/Dice.module.css';

const numbers = [one, two, three, four, five, six];

const Dice = props => {
    const number = useSelector(state => state.pomezia.databaseData.dices[props.side])

    return (
        <div
            className={styles.Dice}
            onClick={props.click}>
                <img className={styles.DiceImg} alt='dice face' src={numbers[number - 1]} />
        </div>
    );
};

export default Dice;