export const Colors = {
	brick: '#bd3512',
	wood: '#0b4e0b',
	sheep: '#20c32e',
	stone: '#4e8595',
	wheat: '#d1ce0e',
	mcard: '#fff',
	desert: '#d39728',
	numberTile: 'white',
	fiumani: 'red',
	gambone: '#9d28d3',
	grassi: 'blue',
	santancini: 'white',
	hover: 'rgba(255, 255, 255, 0.2)'
};
