import React from 'react';

import fiumani from '../05_assets/images/faces/fSquare.png';
import gambone from '../05_assets/images/faces/zSquare.png';
import grassi from '../05_assets/images/faces/gSquare.png';
import santancini from '../05_assets/images/faces/sSquare.png';
import sax from '../05_assets/images/misc/saxophone.png';
import streetsign from '../05_assets/images/misc/street-sign.png';

import styles from '../02_styles/Header.module.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const pics = {
    fiumani,
    gambone,
    grassi,
    santancini
}

const Header = props => {
    const player = useSelector(state => state.saxbar.player);
    const history = useHistory();

    return (
        <div className={props.login ? styles.HeaderLogin : styles.Header}>
            {!props.login && <div className={styles.PlayerFace}>
                <img className={styles.PlayerPic} alt='playerOne' src={pics[player]} onClick={props.openModal}/>
            </div>}
            <div className={props.login ? styles.TitleLogin : styles.Title}>
                <img style={{height: '1em', marginRight: '-10px'}} alt='sax' src={sax} />ax Bar{!props.login && ' - '}{props.title}
            </div>
            {!props.login && <div className={styles.Controls}>
                {props.showHomeButton && (
                    <div className={styles.ControlButton} onClick={() => history.push('/')}><img style={{height: '3vh', width: '3vh'}} alt='home' src={streetsign} /></div>
                )}
            </div>}
        </div>
    )
};

export default Header;