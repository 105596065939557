import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../01_components/Header';

import { setPlayer } from '../04_store/00_actions/saxbarActions';

import gra from '../05_assets/images/misc/gra.jpg';

import styles from '../02_styles/Home.module.css';
import { useLocalStorage } from 'react-use-storage';

const HomeModal = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [_, __, removeStoredPlayer] = useLocalStorage('player', null);

    const logoutHandler = () => {
        history.push('/');
        removeStoredPlayer();
        dispatch(setPlayer(null));
    }

    return (
        <div className={styles.HomeModal}>
            <div className={styles.LogoutButton} onClick={logoutHandler}>Log-out</div>
        </div>
    )
}

const Home = props => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const switchModalHandler = () => {
        setShowModal(!showModal);
    }

    return (
        <div className={styles.Home}>
            {showModal && <HomeModal closeModal={switchModalHandler} />}
            <Header title='Home' openModal={switchModalHandler}/>
            <div className={styles.Body}>
                <div className={styles.GamesBox}>
                    <img alt='gra' src={gra} style={{position: 'absolute', width: '100%', height: '100%'}} />
                    <div className={styles.Paletto} />
                    <div className={styles.Game} onClick={() => history.push('/pomezia')}>
                        <div className={styles.StreetSign}>
                                POMEZIA {'--->'} 
                            </div>
                        </div>
                    <div className={styles.Game}>
                        <div className={styles.StreetSign}>
                            {'<---'} CAPANNELLE
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home;